import { organizationUuid } from '@/models/Organization';
import { Component } from 'vue-property-decorator';
// eslint-disable-next-line import/no-duplicates
import { StatisticsColumn } from '@/components/week-statistics-view/WeekStatisticsView';
// eslint-disable-next-line import/no-duplicates
import { ReportTypes } from '@/support/ReportTypes';
import RapportAantallenPerType from '../RapportAantallenPerType/RapportAantallenPerType';

@Component<RapportAantallenPerTypeRegulier>({
  components: {
    LoaderCard: () => import('@/components/loader-card/LoaderCard.vue'),
    WeekStatisticsView: () => import('@/components/week-statistics-view/WeekStatisticsView.vue'),
  },
})
export default class RapportAantallenPerTypeRegulier extends RapportAantallenPerType {
  // #region Methods

  protected parseTotals(response: any): void {
    this.totals = [];

    (response.data as ManagementReports[]).forEach((data: ManagementReports) => {
      if (data) {
        const item: ModifiedManagementReports = { ...data, ...{} };

        const isIMG = organizationUuid.img === this.organization;
        const statuses = '&statuses=["planning_organization", "opname_in_progress", "opname_rejected", "opname_done", "in_progress", "supervisor", "rejected", "submitted", "checking", "veldwerk_rejected"]';

        item.links = {
          total: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=total&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          external: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=external&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          planned: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=planned&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          replanned: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=replanned&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          todo: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=todo&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
        };

        if (this.activeTab === 'regulier') {
          item.links = {
            total: `/reports?types=["${ReportTypes.REGULIER_2021}"]&exclude_types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=total&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            external: `/reports?types=["${ReportTypes.REGULIER_2021}"]&exclude_types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=external&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            planned: `/reports?types=["${ReportTypes.REGULIER_2021}"]&exclude_types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=planned&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            replanned: `/reports?types=["${ReportTypes.REGULIER_2021}"]&exclude_types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=replanned&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            todo: `/reports?types=["${ReportTypes.REGULIER_2021}"]&exclude_types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=todo&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          };
        }

        if (this.activeTab === 'special') {
          item.links = {
            total: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=total_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            external: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=external_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            planned: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=planned_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            replanned: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=replanned_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            todo: `/reports?types=["${ReportTypes.REGULIER_2021}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=todo_special&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          };
        }

        if (this.activeTab === 'ves') {
          item.links = {
            total: `/reports?types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=total&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            external: `/reports?types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=external&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            planned: `/reports?types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=planned&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            replanned: `/reports?types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=replanned&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
            todo: `/reports?types=["${ReportTypes.VES_NULMETING}"]&opname_variant=["${item.opname_variant}"]${statuses}&filter=todo&${! isIMG ? `organizations=["${this.organization}"]` : ''}`,
          };
        }

        this.totals.push(item);
      }
    });
  }

  // #endregion

  // #region Getters / Setters

  protected get columns(): StatisticsColumn[] {
    return [
      {
        key: 'label',
        name: 'Opname variant',
        class: 'xs2 md2 lg2',
      },
      ...this.generalColumns,
    ];
  }

  protected get payload() {
    return {
      signature: 'management-reports:bureau-report-count:opname-variants',
      body: this.currentFilters,
    };
  }

  // #endregion
}

// #region Interfaces

interface ManagementReports {
  external: number;
  label?: string;
  opname_variant?: string;
  uuid: string;
  report_type: string;
  planned: number;
  replanned: number;
  status: string;
  todo: number;
  total: number;
}

interface ModifiedManagementReports extends ManagementReports {
  links?: {[key: string]: string};
}

// #endregion
